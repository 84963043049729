import React from "react";
import ReactModal from "react-modal";
import styles from "./ErrorPopup.module.scss";

export function errorPopup() {
  return (
    <ReactModal
      isOpen={this.state.isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.setIsClose}
      className={styles.detailsPopupContainer}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.offerContainer}>
        <span
          className={styles.closeBtn}
          onClick={this.setIsClose}
          data-testid="span-close"
        >
          &times;
        </span>
        <div className={styles.firstContainer}>
          Oops! Something went wrong. The error has been logged and we’re
          working to fix the problem.
        </div>
        <div className={styles.secondContainer}>
          In the meantime, here are some things you can try:
          <ul>
            <li>Refresh the page.</li>
            <li>Log out and log back in.</li>
            <li>
              Email us at <span>hello@{this.getDomainName()}</span> and tell us
              what happened.{" "}
            </li>
          </ul>
        </div>
      </div>
    </ReactModal>
  );
}
