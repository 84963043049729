import React, { Component, createRef } from "react";
import styles from "./ConfirmedOffers.module.scss";
import { getSubDomain, setTitle } from "../../../component/ThemeManager/helper";
import HorizontalMenu from "../../../component/HorizontalMenu";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import { FinePrintModal, getShortNumber } from "../YourAdvance/helper";
import Odometer from "react-odometerjs";
import { get, invoke, isObject, values } from "lodash";
import Tooltip from "../../../component/Tooltip/Tooltip";
import Select from "react-select";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import {
  CURRENT_DISTRIBUTOR_LABEL,
  DEFAULT_PARTNER,
} from "../YourAdvance/constants";
import Image from "../../../component/Image";
import {
  ACCEPT_OFFER,
  API_URL,
  ARTIST_API,
  CONFIRMED_OFFER_STEP,
  CONFIRMED_OFFERS_API,
  ERROR_SERVER_DOWN,
  OFFER_SUMMARY,
  TOP_TRACK,
  USER_API,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage, GetIframeVideo } from "../helper";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ReactComponent as IconDownArrow } from "../../../assets/logos/dropDownArrow.svg";
import ConfirmedOffersV2 from "../ConfirmedOffersV2/ConfirmedOffersV2";
import moment from "moment";
import { MAIN_QUESTION_OPTIONS } from "../ConfirmedOffersV2/constants";
import { ReactComponent as ToolTip } from "../../../assets/logos/help-icon.svg";
import { FIXED_DEALS, OFFER_KEYS, YT_URL, YT_VIDEO_DESC } from "./constants";
import ReactModal from "react-modal";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import PropTypes from "prop-types";
import segment from "../../../utils/segment";
import { BB_SUBDOMAINS } from "../../../component/ThemeManager/constants";

class ConfirmedOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingRange: false,
      shortLoading: false,
      advanceData: {},
      currentData: {
        tail_term: 0,
        tail_rev_share: "0%",
      },
      defaultOffer: {},
      flatOffer: [],
      selectedDeal: "",
      offerStage: {},
      selectedAdvance: 0,
      navigationStatus: {},
      selectedArtist: {},
      availablePartners: [],
      selectedPartner: null,
      partnersOptions: {},
      isFinePrint: false,
      isSaveOffer: false,
      advanceOpened: false,
      minMaxValues: {
        advance: {
          min: 0,
          max: 0,
        },
      },
      selections: {
        partner: null,
        works: null,
        flow_through: null,
        royalty: null,
        tail_term: null,
        tail_rev_share: null,
      },
      filteredOptions: {},
      groupedValues: {},
      isOfferScreen: false,
      showMoreData: false,
      isModalOpen: false,
      videoTitle: null,
      videoDescription: null,
      ytUrl: null,
      toggleOptions: {
        flowThroughOptions: [],
        royaltyOptions: [],
        tailTermOptions: [],
        tailShareOptions: [],
        distributorOptions: [],
      },
      confirmedData: {},
    };
    this.groupedValuesRef = createRef();
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  componentDidUpdate(prevProps, prevState) {
    setTitle("CONFIRMED OFFERS", this.context);
    if (
      get(this.state, "isOfferScreen") &&
      get(prevState, "isOfferScreen") !== get(this.state, "isOfferScreen")
    ) {
      invoke(segment, "track.viewedFinalOfferV2");
      this.getOffers(true);
    }
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (get(res, "status")) {
          this.checkSteps(
            res.data,
            get(this.props, "location.state.fromDashboard"),
          );

          this.setState({
            offerStage: res.data.offerStage,
            availablePartners: get(res, "data.availablePartners", []),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  checkSteps = (data, fromDashboard) => {
    if (fromDashboard) {
      if (
        get(data, "offerStage.defaultOffer.isAccepted") ||
        (get(data, "offerStage.verificationStatus.adjustTerms") &&
          get(data, "offerStage.verificationStatus.offerSummary"))
      ) {
        this.props.history.push({
          pathname: ACCEPT_OFFER,
          state: { reviewData: data },
        });
        return false;
      }
      if (!get(data, "renderNewConfirmedOffers")) {
        invoke(this.props, "history.push", OFFER_SUMMARY);
        return false;
      }
    }
    this.getMinMaxRange();
    return true;
  };

  getMinMaxRange = () => {
    this.setState({ loadingRange: true });
    const data = {
      method: "GET",
    };

    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFER_STEP}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loadingRange: false,
        });
        if (get(res, "status")) {
          this.setState({
            offerMinMax: {
              min: get(res, "data.confirmedOfferSteps.min"),
              max: get(res, "data.confirmedOfferSteps.max"),
            },
            confirmedOfferSteps: get(res, "data.confirmedOfferSteps"),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loadingRange: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  getPartnerOptionObject = (data) => {
    let currObject = get(data, "offers", {});
    let prevObj = null;
    while (isObject(values(currObject)[0])) {
      prevObj = currObject;
      currObject = values(currObject)[0];
    }
    return prevObj;
  };

  getOffers = (loadDefault) => {
    this.setState({ loading: false, shortLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false, shortLoading: false });
        if (res.status) {
          this.setState(
            {
              advanceData: get(res, "data.offers", {}),
              defaultOffer: get(res, "data.selectedOffer", {}),
              currentData: {
                tail_term: get(res, "data.selectedOffer.tail_term"),
                tail_rev_share: get(res, "data.selectedOffer.tail_rev_share"),
                partner: get(res, "data.selectedOffer.partner"),
                works: get(
                  res,
                  "data.selectedOffer.works",
                  Object.keys(get(res, "data.offers", {}))[0],
                ),
                flow_through: get(res, "data.selectedOffer.flow_through"),
                royalty: get(res, "data.selectedOffer.royalty"),
              },
              selections: {
                works: get(
                  res,
                  "data.selectedOffer.works",
                  Object.keys(get(res, "data.offers", {}))[0] || null,
                ),
                flow_through: get(res, "data.selectedOffer.flow_through", null),
                royalty: get(res, "data.selectedOffer.royalty", null),
                tail_term: get(res, "data.selectedOffer.tail_term", null),
                tail_rev_share: get(
                  res,
                  "data.selectedOffer.tail_rev_share",
                  null,
                ),
                partner: this.isPartnerFlow()
                  ? get(res, "data.selectedOffer.partner", null)
                  : null,
              },
              selectedPartner: get(
                res,
                "data.selectedOffer.partner",
                get(this.state, "availablePartners[0]", null),
              ),
              partnersOptions: this.getPartnerOptionObject(
                get(res, "data", {}),
              ),
            },
            () => {
              this.updateToggleOptions(false, false, false, false, () => {
                this.updateMinMaxValues(
                  JSON.parse(JSON.stringify(this.state.advanceData)),
                  loadDefault,
                );
              });
            },
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false, shortLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleSaveOffer = () => {
    invoke(segment, "track.selectedFinalOffer");
    this.setState({ loading: false, shortLoading: true, isSaveOffer: true });
    const payload = {
      offer: {
        ...this.state.confirmedData,
        total_advance: get(this.state, "minMaxValues.advance.min"),
      },
      adjustTerms: true,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
          shortLoading: false,
          isSaveOffer: false,
        });
        if (res.status) {
          this.props.history.push(ACCEPT_OFFER);
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({
          shortLoading: false,
          loading: false,
          isSaveOffer: false,
        });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  toggleFinePrintText = () => {
    this.setState({ isFinePrint: !this.state.isFinePrint });
  };

  flattenObject = (data) => {
    const stack = [data];
    const values = [];
    const minMaxValues = {
      advance: { min: Infinity, max: 0 },
      royalty: { min: Infinity, max: 0 },
      option_advance: { min: Infinity, max: 0 },
      nr_advance: { min: Infinity, max: 0 },
      catalog_advance: { min: Infinity, max: 0 },
      recoup_year_lower: Infinity,
      recoup_year_higher: 0,
    };

    while (stack.length > 0) {
      const current = stack.pop();
      if (get(current, "works")) {
        values.push(current);

        const advance = get(current, "total_advance", 0);
        const royalty = parseFloat(get(current, "royalty", 0));
        const option_advance = parseFloat(get(current, "option_advance", 0));
        const nr_advance = parseFloat(get(current, "nr_advance", 0));
        const catalog_advance = parseFloat(get(current, "catalog_advance", 0));
        const recoup_year_lower = parseFloat(
          get(current, "recoup_year_lower", 0),
        );
        const recoup_year_higher = parseFloat(
          get(current, "recoup_year_higher", 0),
        );

        minMaxValues.advance.min = Math.min(minMaxValues.advance.min, advance);
        minMaxValues.advance.max = Math.max(minMaxValues.advance.max, advance);
        minMaxValues.royalty.min = Math.min(minMaxValues.royalty.min, royalty);
        minMaxValues.royalty.max = Math.max(minMaxValues.royalty.max, royalty);

        minMaxValues.option_advance.min = Math.min(
          minMaxValues.option_advance.min,
          option_advance,
        );
        minMaxValues.option_advance.max = Math.max(
          minMaxValues.option_advance.max,
          option_advance,
        );

        minMaxValues.nr_advance.min = Math.min(
          minMaxValues.nr_advance.min,
          nr_advance,
        );
        minMaxValues.nr_advance.max = Math.max(
          minMaxValues.nr_advance.max,
          nr_advance,
        );

        minMaxValues.catalog_advance.min = Math.min(
          minMaxValues.catalog_advance.min,
          catalog_advance,
        );
        minMaxValues.catalog_advance.max = Math.max(
          minMaxValues.catalog_advance.max,
          catalog_advance,
        );
        minMaxValues.recoup_year_lower = Math.min(
          minMaxValues.recoup_year_lower,
          recoup_year_lower,
        );
        minMaxValues.recoup_year_higher = Math.max(
          minMaxValues.recoup_year_higher,
          recoup_year_higher,
        );
      }
      for (const key in current) {
        if (current[key] && typeof current[key] === "object") {
          stack.push(current[key]);
        }
      }
    }

    return { values, minMaxValues };
  };

  generateGroupedValues = (data) => {
    const groupedValues = {};
    if (!get(data, "length")) return groupedValues;
    Object.keys(this.state.selections).forEach((field) => {
      groupedValues[field] = [];
    });
    invoke(data, "forEach", (item) => {
      Object.keys(groupedValues).forEach((field) => {
        if (!groupedValues[field].includes(item[field])) {
          groupedValues[field].push(item[field]);
        }
      });
    });
    Object.keys(groupedValues).forEach((field) => {
      groupedValues[field].sort((a, b) =>
        typeof a === "number" ? a - b : invoke(a, "localeCompare", b),
      );
    });

    return groupedValues;
  };

  filterOptions = (data, update = true) => {
    const { selections } = this.state;

    const minMaxValues = {
      advance: { min: Infinity, max: 0 },
      royalty: { min: Infinity, max: 0 },
      option_advance: { min: Infinity, max: 0 },
      nr_advance: { min: Infinity, max: 0 },
      catalog_advance: { min: Infinity, max: 0 },
      recoup_year_lower: Infinity,
      recoup_year_higher: 0,
    };
    const grouped = JSON.parse(JSON.stringify(this.groupedValuesRef.current));
    const filtered = {};
    const fields = Object.keys(grouped);
    fields.forEach((field) => {
      filtered[field] = [];
    });

    data.forEach((item) => {
      let matchesAllSelections = true;
      Object.keys(selections).forEach((selField) => {
        if (
          [null, undefined].indexOf(selections[selField]) === -1 &&
          selections[selField] !== item[selField]
        ) {
          matchesAllSelections = false;
        }
      });
      if (matchesAllSelections) {
        fields.forEach((field) => {
          if (!filtered[field].includes(item[field])) {
            filtered[field].push(item[field]);
          }
        });

        const advance = get(item, "total_advance", 0);
        const royalty = parseFloat(get(item, "royalty", 0));
        const option_advance = parseFloat(get(item, "option_advance", 0));
        const nr_advance = parseFloat(get(item, "nr_advance", 0));
        const catalog_advance = parseFloat(get(item, "catalog_advance", 0));
        const recoup_year_lower = parseFloat(get(item, "recoup_year_lower", 0));
        const recoup_year_higher = parseFloat(
          get(item, "recoup_year_higher", 0),
        );

        minMaxValues.advance.min = Math.min(minMaxValues.advance.min, advance);
        minMaxValues.advance.max = Math.max(minMaxValues.advance.max, advance);
        minMaxValues.royalty.min = Math.min(minMaxValues.royalty.min, royalty);
        minMaxValues.royalty.max = Math.max(minMaxValues.royalty.max, royalty);
        minMaxValues.option_advance.min = Math.min(
          minMaxValues.option_advance.min,
          option_advance,
        );
        minMaxValues.option_advance.max = Math.max(
          minMaxValues.option_advance.max,
          option_advance,
        );
        minMaxValues.nr_advance.min = Math.min(
          minMaxValues.nr_advance.min,
          nr_advance,
        );
        minMaxValues.nr_advance.max = Math.max(
          minMaxValues.nr_advance.max,
          nr_advance,
        );
        minMaxValues.catalog_advance.min = Math.min(
          minMaxValues.catalog_advance.min,
          catalog_advance,
        );
        minMaxValues.catalog_advance.max = Math.max(
          minMaxValues.catalog_advance.max,
          catalog_advance,
        );
        minMaxValues.recoup_year_lower = Math.min(
          minMaxValues.recoup_year_lower,
          recoup_year_lower,
        );
        minMaxValues.recoup_year_higher = Math.max(
          minMaxValues.recoup_year_higher,
          recoup_year_higher,
        );
      }
    });
    Object.keys(filtered).forEach((field) => {
      filtered[field] = [...new Set(filtered[field])].sort((a, b) =>
        typeof a === "number" ? a - b : invoke(a, "localeCompare", b),
      );
    });

    let resultantObj = {};
    if (get(minMaxValues, "advance.min") === get(minMaxValues, "advance.max")) {
      resultantObj = {
        partner: get(
          this.state,
          "currentData.partner",
          get(
            filtered,
            "partner.0",
            get(this.state.filteredOptions, "partner.0"),
          ),
        ),
        works: get(this.state, "selections.works"),
        total_advance: get(minMaxValues, "advance.min"),
        royalty: get(minMaxValues, "royalty.min"),
        option_advance: get(minMaxValues, "option_advance.min"),
        nr_advance: get(minMaxValues, "nr_advance.min"),
        catalog_advance: get(minMaxValues, "catalog_advance.min"),
        recoup_year_lower: get(minMaxValues, "recoup_year_lower"),
        recoup_year_higher: get(minMaxValues, "recoup_year_higher"),
        flow_through: get(
          filtered,
          "flow_through.0",
          get(this.state.filteredOptions, "flow_through.0"),
        ),
        tail_term: get(
          filtered,
          "tail_term.0",
          get(this.state.filteredOptions, "tail_term.0"),
        ),
        tail_rev_share: get(
          filtered,
          "tail_rev_share.0",
          get(this.state.filteredOptions, "tail_rev_share.0"),
        ),
      };
      if (resultantObj.royalty) {
        resultantObj.royalty = `${resultantObj.royalty}%`;
      }
    }

    this.setState({
      filteredOptions: update ? filtered : this.state.filteredOptions,
      minMaxValues,
      ...(Object.values(resultantObj).length
        ? {
            confirmedData: {
              tail_term: get(resultantObj, "tail_term"),
              tail_rev_share: get(resultantObj, "tail_rev_share"),
              partner: get(resultantObj, "partner"),
              works: get(resultantObj, "works"),
              flow_through: get(resultantObj, "flow_through"),
              royalty: get(resultantObj, "royalty"),
            },
          }
        : {}),
    });
  };

  updateMinMaxValues = (data, firstTime) => {
    const relevantData = this.flattenObject(data);
    if (firstTime) {
      const groupedValues = this.generateGroupedValues(relevantData.values);
      this.groupedValuesRef.current = groupedValues;
      this.filterOptions(relevantData.values);
      this.setState({ groupedValues });
    }
    this.setState({
      flatOffer: [...relevantData.values],
    });
  };

  updateToggleOptions = (
    royaltyOptionSelected,
    termLengthOptionSelected,
    afterRecoupmentOptionSelected,
    onToggleClick = false,
    callback = () => {},
  ) => {
    const flowThroughOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}`,
        {},
      ),
    ).map(Number);

    const royaltyOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          flowThroughOptions[0],
        )}`,
        {},
      ),
    );

    const tailTermOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          flowThroughOptions[0],
        )}.${get(this.state, "currentData.royalty", royaltyOptions[0])}`,
        {},
      ),
    ).map(Number);

    const tailShareOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          flowThroughOptions[0],
        )}.${get(this.state, "currentData.royalty", royaltyOptions[0])}.${get(
          this.state,
          "currentData.tail_term",
          tailTermOptions[0],
        )}`,
        {},
      ),
    );

    const distributorOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          flowThroughOptions[0],
        )}.${get(this.state, "currentData.royalty", royaltyOptions[0])}.${get(
          this.state,
          "currentData.tail_term",
          tailTermOptions[0],
        )}.${get(
          this.state,
          "currentData.tail_rev_share",
          tailShareOptions[0],
        )}`,
        {},
      ),
    );
    this.setState((prevState) => {
      const newSelections = {
        flow_through:
          prevState.selections.flow_through ?? flowThroughOptions[0],
        royalty: prevState.selections.royalty ?? royaltyOptions[0],
        tail_term: prevState.selections.tail_term ?? tailTermOptions[0],
        tail_rev_share:
          prevState.selections.tail_rev_share ?? tailShareOptions[0],
      };
      let defaultTailTerm;
      if (tailTermOptions.length === 1) {
        defaultTailTerm = tailTermOptions[0];
      }
      let defaultAfterRecoupment;
      if (tailShareOptions.length === 1) {
        defaultAfterRecoupment = tailShareOptions[0];
      }
      let defaultPartner;
      if (distributorOptions.length === 1) {
        defaultPartner = distributorOptions[0];
      }

      return {
        toggleOptions: {
          ...prevState.toggleOptions,
          flowThroughOptions,
          royaltyOptions,
          tailTermOptions,
          tailShareOptions,
          distributorOptions,
        },
        ...(onToggleClick
          ? {}
          : {
              currentData: {
                ...prevState.currentData,
                ...newSelections,
              },
              selections: {
                ...prevState.selections,
                ...newSelections,
              },
            }),
        ...(defaultTailTerm && royaltyOptionSelected
          ? {
              currentData: {
                ...prevState.currentData,
                tail_term: defaultTailTerm,
                tail_rev_share: defaultAfterRecoupment,
                partner: defaultPartner,
              },
              selections: {
                ...prevState.selections,
                tail_term: defaultTailTerm,
                tail_rev_share: defaultAfterRecoupment,
                partner: defaultPartner,
              },
            }
          : {}),
        ...(defaultAfterRecoupment && termLengthOptionSelected
          ? {
              currentData: {
                ...prevState.currentData,
                tail_rev_share: defaultAfterRecoupment,
                partner: defaultPartner,
              },
              selections: {
                ...prevState.selections,
                tail_rev_share: defaultAfterRecoupment,
                partner: defaultPartner,
              },
            }
          : {}),
        ...(defaultPartner && afterRecoupmentOptionSelected
          ? {
              currentData: {
                ...prevState.currentData,
                partner: defaultPartner,
              },
              selections: {
                ...prevState.selections,
                partner: defaultPartner,
              },
            }
          : {}),
      };
    }, callback);
  };

  isPartnerFlow = () => !!get(this.state, "availablePartners.length");

  loaderText = () => {
    if (this.state.isSaveOffer) {
      return (
        <p>
          We are generating <br /> your<span> deal summary</span>
        </p>
      );
    }
    return (
      <p>
        We are retrieving <br /> your<span> offers</span>
      </p>
    );
  };

  getPartnerOptions = () => {
    let partners;

    if (get(this.state, "toggleOptions.distributorOptions.length", 0) > 0) {
      partners = this.state.toggleOptions.distributorOptions;
    } else {
      partners = get(this.state, "availablePartners", []);
    }

    return partners.map((o) => ({
      label: o === DEFAULT_PARTNER ? CURRENT_DISTRIBUTOR_LABEL : o,
      value: o,
    }));
  };

  getSelectedPartner = () =>
    get(this.state, "currentData.partner", null) && {
      label:
        get(this.state, "currentData.partner") === DEFAULT_PARTNER
          ? CURRENT_DISTRIBUTOR_LABEL
          : get(this.state, "currentData.partner"),
      value: get(this.state, "currentData.partner"),
    };

  getWorksOptions = () =>
    Object.keys(get(this.state, "advanceData", {})).map((o) => ({
      label: o,
      value: o,
    }));

  formatOptionLabel = (op) => (
    <div className={styles.option}>
      {op.value !== DEFAULT_PARTNER && (
        <Image
          src={get(this.state, `partnersOptions.${op.value}.logo`)}
          alt={op.value}
        />
      )}
      <span>{op.label}</span>
    </div>
  );

  handleChangeOffer = (field, value, update = true) => {
    const keyOrder = [
      "works",
      "flow_through",
      "royalty",
      "tail_term",
      "tail_rev_share",
      "partner",
    ];

    const currentData = {
      ...get(this.state, "currentData", {}),
      [field]: value === null ? undefined : value,
    };
    const keyIndex = keyOrder.indexOf(field);
    const selections = { ...get(this.state, "selections", {}), [field]: value };
    if (keyIndex !== -1) {
      for (let i = keyIndex + 1; i < keyOrder.length; i++) {
        const nextKey = keyOrder[i];
        delete currentData[nextKey];
        selections[nextKey] = null;
      }
    }
    const royaltyOptionSelected = field === "royalty" && value !== null;
    const termLengthOptionSelected = field === "tail_term" && value !== null;
    const afterRecoupmentOptionSelected =
      field === "tail_rev_share" && value !== null;

    this.setState(
      (prev) => ({
        currentData,
        selections,
      }),
      () => {
        this.updateToggleOptions(
          royaltyOptionSelected,
          termLengthOptionSelected,
          afterRecoupmentOptionSelected,
          true,
        );
        this.filterOptions(this.state.flatOffer, update);
      },
    );
  };

  renderMinMaxOffer = (key) => {
    return get(this.state, `minMaxValues.${key}.min`) ===
      get(this.state, `minMaxValues.${key}.max`) ? (
      <h3>
        $
        {get(this.state, `minMaxValues.${key}.min`) !== Infinity && (
          <Odometer
            value={get(this.state, `minMaxValues.${key}.min`)}
            format="(,ddd).dd"
            duration={400}
          />
        )}
      </h3>
    ) : (
      <h3>
        $
        {get(this.state, `minMaxValues.${key}.min`) !== Infinity && (
          <Odometer
            value={getShortNumber(get(this.state, `minMaxValues.${key}.min`))}
            format="(,ddd).dd"
            duration={400}
          />
        )}
        {`${getShortNumber(
          get(this.state, `minMaxValues.${key}.min`),
        )}`.replace(/[\d,\.]/g, "")}{" "}
        -{" "}
        {get(this.state, `minMaxValues.${key}.max`) !== -Infinity && (
          <Odometer
            value={getShortNumber(get(this.state, `minMaxValues.${key}.max`))}
            format="(,ddd).dd"
            duration={400}
          />
        )}
        {`${getShortNumber(
          get(this.state, `minMaxValues.${key}.max`),
        )}`.replace(/[\d,\.]/g, "")}
      </h3>
    );
  };

  getYTURL = (title) => {
    let ytUrl;
    if (BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1) {
      ytUrl = YT_URL[title].BB;
    } else {
      ytUrl = YT_URL[title].CC;
    }
    return ytUrl;
  };

  renderVideoContainer = (title) => {
    const index = OFFER_KEYS.indexOf(title);
    const videoTitle = OFFER_KEYS[index];
    const ytUrl = this.getYTURL(Object.keys(YT_URL)[index]);
    const videoDescription = YT_VIDEO_DESC[index];
    this.setState({ isModalOpen: true, videoTitle, videoDescription, ytUrl });
  };

  renderModal = () => {
    return (
      <ReactModal
        isOpen={this.state.isModalOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.handleCloseModal}
        className={styles.detailsPopupContainer}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.videoContainer}>
          <h2>{this.state.videoTitle}</h2>
          <span className={styles.closeBtn} onClick={this.handleCloseModal}>
            {" "}
            &times;
          </span>
          <div className={styles.video} style={{ cursor: "pointer" }}>
            <GetIframeVideo url={this.state.ytUrl} />
          </div>
          <p>{this.state.videoDescription}</p>
        </div>
      </ReactModal>
    );
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, videoTitle: "" });
  };

  renderOfferInfo = () => (
    <>
      {get(this.state, "currentData.works") && (
        <div className={styles.royalty}>
          <p>Works</p>
          <p>{get(this.state, "currentData.works")}</p>
        </div>
      )}
      {!!get(this.state, "currentData.flow_through") && (
        <div className={styles.royalty}>
          <p>
            <span>Income Paid Through Before Recoupment</span>
          </p>
          <p>{get(this.state, "currentData.flow_through")}%</p>
        </div>
      )}
      {!this.isFixedOffer() && (
        <div className={styles.royalty}>
          <p>
            <span>Recoupment Rate</span>
          </p>
          {get(this.state, "currentData.royalty") ? (
            <p>{get(this.state, "minMaxValues.royalty.min")}%</p>
          ) : (
            <p data-testid="royaltyValue">
              {get(this.state, "minMaxValues.royalty.min")}% -{" "}
              {get(this.state, `minMaxValues.royalty.max`)}%
            </p>
          )}
        </div>
      )}
      {!this.isFixedOffer() && !!get(this.state, `currentData.tail_term`) && (
        <div className={styles.royalty}>
          <p>
            <span>Post-Recoup Term Length</span>
          </p>
          <p>
            {get(this.state, `currentData.tail_term`)}{" "}
            {get(this.state, `currentData.tail_term`) === 1 ? "Year" : "Years"}{" "}
          </p>
        </div>
      )}
      {!this.isFixedOffer() &&
        !!parseFloat(get(this.state, "currentData.tail_rev_share")) && (
          <div className={styles.royalty}>
            <p>
              <span>Income Paid Through After Recoupment </span>
            </p>
            <p>{get(this.state, "currentData.tail_rev_share")}</p>
          </div>
        )}
    </>
  );

  toggleShowMoreData = () => {
    this.setState({ showMoreData: !get(this.state, "showMoreData") });
  };

  isFixedOffer = () =>
    FIXED_DEALS.indexOf(get(this.state, "currentData.works")) !== -1;

  renderCustomizeOffers = () => {
    return (
      <div className={styles.customizeFundingContainer}>
        <div className={styles.rightContainer}>
          <div className={styles.floatHeader}>
            <div className={styles.titleContainer}>
              <h2>
                <span>Confirmed offer summary</span>
              </h2>
              <p>
                Valid through{" "}
                {moment(
                  get(this.state, "offerStage.defaultOffer.expire"),
                ).format("MMMM DD,YYYY")}
              </p>
            </div>
            <div className={styles.offerOverview}>
              <div className={styles.advanceContainer}>
                <p>Total Advance</p>
                {this.renderMinMaxOffer("advance")}
              </div>
              {!!get(this.state, "minMaxValues.nr_advance.max") && (
                <div
                  data-testid="upfrontAdvance"
                  className={styles.otherOfferContainer}
                >
                  <p>Upfront advance</p>
                  {this.renderMinMaxOffer("catalog_advance")}
                </div>
              )}
              {!!get(this.state, "minMaxValues.nr_advance.max") && (
                <div
                  data-testid="newReleaseAdvance"
                  className={styles.otherOfferContainer}
                >
                  <p>New release advance</p>
                  {this.renderMinMaxOffer("nr_advance")}
                </div>
              )}
              {!!get(this.state, "minMaxValues.option_advance.max") && (
                <div
                  data-testid="optionAdvance"
                  className={styles.otherOfferContainer}
                >
                  <p>Option advance</p>
                  {this.renderMinMaxOffer("option_advance")}
                </div>
              )}
            </div>
            <div className={styles.offerInfoContainer}>
              {!this.isFixedOffer() &&
                !!get(this.state, `minMaxValues.recoup_year_higher`) && (
                  <div data-testid="timeToRecoup" className={styles.royalty}>
                    <p>
                      <span>Est. Time to Recoup</span>
                    </p>
                    <p>
                      {get(this.state, "minMaxValues.recoup_year_lower")} -{" "}
                      {get(this.state, `minMaxValues.recoup_year_higher`)} Years
                    </p>
                  </div>
                )}
              {!!get(this.state, "currentData.partner") &&
                get(this.state, "currentData.partner") !== DEFAULT_PARTNER && (
                  <div className={styles.royalty}>
                    <p>
                      <span>Distribution Fee </span>
                    </p>
                    <p>
                      {get(
                        this.state,
                        `partnersOptions.${get(
                          this.state,
                          "currentData.partner",
                        )}.fees`,
                      )}
                    </p>
                  </div>
                )}
              {get(this.state, "showMoreData") && this.renderOfferInfo()}
              <div
                className={styles.collapseBtn}
                onClick={this.toggleShowMoreData}
              >
                {get(this.state, "showMoreData") ? (
                  <>
                    <p>Hide Details</p> <IconDownArrow className={styles.up} />
                  </>
                ) : (
                  <>
                    <p>Show More Details</p> <IconDownArrow />
                  </>
                )}
              </div>
            </div>

            <div className={styles.submitContainer}>
              <button
                onClick={this.handleSaveOffer}
                disabled={
                  get(this.state, `minMaxValues.advance.min`) !==
                  get(this.state, `minMaxValues.advance.max`)
                }
              >
                Select Deal
              </button>
            </div>
          </div>
          <div className={styles.desktopFinePrint}>
            <button
              className={styles.linkBtn}
              onClick={this.toggleFinePrintText}
            >
              Read the Fine Print
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <h2>Deal Details</h2>
          </div>
          <div className={styles.selectorContainer}>
            <div className={styles.fieldContainer}>
              <label>
                Deal Type
                <span
                  data-testid="WorksTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[0])}
                >
                  <ToolTip />
                </span>
              </label>
              <Select
                classNamePrefix="offers"
                styles={distributorSelectStyle}
                onChange={(op) => {
                  this.handleChangeOffer("works", get(op, "value"));
                }}
                options={this.getWorksOptions()}
                placeholder={`Select One`}
                value={
                  get(this.state, "currentData.works") && {
                    label: get(this.state, "currentData.works"),
                    value: get(this.state, "currentData.works"),
                  }
                }
                components={{ IndicatorSeparator: null }}
                aria-label="works-select-input"
              />
            </div>
            <div className={styles.fieldContainer}>
              <label>
                Income Paid Through Before Recoupment
                <span
                  data-testid="BeforeRecoupTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[1])}
                >
                  <ToolTip />
                </span>
              </label>
              <ToggleButtonGroup
                name="flow_through"
                className={styles.toggleButton}
                exclusive
                value={get(this.state, "currentData.flow_through")}
                onChange={(e, val) => {
                  this.handleChangeOffer("flow_through", val);
                }}
              >
                {get(this.state, "groupedValues.flow_through", []).map(
                  (item) => {
                    const isDisabled =
                      get(
                        this.state,
                        "toggleOptions.flowThroughOptions",
                        [],
                      ).indexOf(item) === -1 || this.isFixedOffer();
                    return (
                      <ToggleButton
                        key={`flow_through-${item}`}
                        value={item}
                        disableRipple
                        disabled={isDisabled}
                      >
                        {item}%
                      </ToggleButton>
                    );
                  },
                )}
              </ToggleButtonGroup>
            </div>
            <div className={styles.fieldContainer}>
              <label>
                Recoupment Rate
                <span
                  data-testid="RecoupmentTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[2])}
                >
                  <ToolTip />
                </span>
              </label>
              <ToggleButtonGroup
                name="royalty"
                exclusive
                className={styles.toggleButton}
                value={get(this.state, "currentData.royalty")}
                onChange={(e, val) => {
                  this.handleChangeOffer("royalty", val, false);
                }}
                data-testid="royalty-toggle-group"
              >
                {get(this.state, "groupedValues.royalty", [])
                  .sort((a, b) => parseFloat(a) - parseFloat(b))
                  .map((item) => {
                    const isDisabled =
                      get(
                        this.state,
                        "toggleOptions.royaltyOptions",
                        [],
                      ).indexOf(item) === -1 || this.isFixedOffer();
                    return (
                      <ToggleButton
                        key={`royalty-${item}`}
                        value={item}
                        disableRipple
                        data-testid={`royalty-button-${item}`}
                        disabled={isDisabled}
                      >
                        {item}
                      </ToggleButton>
                    );
                  })}
              </ToggleButtonGroup>
            </div>
            <div className={styles.fieldContainer}>
              <label>
                Post-Recoupment Term
                <span
                  data-testid="TermLengthTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[3])}
                >
                  <ToolTip />
                </span>
              </label>
              <ToggleButtonGroup
                name="tail-term"
                exclusive
                className={styles.toggleButton}
                value={get(this.state, "currentData.tail_term")}
                onChange={(e, val) => {
                  this.handleChangeOffer("tail_term", val, false);
                }}
                data-testid="term-toggle-group"
              >
                {get(this.state, "groupedValues.tail_term", []).map((item) => {
                  const isDisabled =
                    get(
                      this.state,
                      "toggleOptions.tailTermOptions",
                      [],
                    ).indexOf(item) === -1 || this.isFixedOffer();
                  return (
                    <ToggleButton
                      key={`tail-term-${item}`}
                      value={item}
                      disableRipple
                      data-testid={`term-button-${item}`}
                      disabled={isDisabled}
                    >
                      {item}
                      {item > 1 ? " Years" : " Year"}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </div>
            {!!parseFloat(get(this.state, "currentData.tail_term")) && (
              <div className={styles.fieldContainer}>
                <label>
                  Income Paid Through After Recoupment
                  <span
                    data-testid="AfterRecoupTooltip"
                    onClick={() => this.renderVideoContainer(OFFER_KEYS[4])}
                  >
                    <ToolTip />
                  </span>
                </label>
                <ToggleButtonGroup
                  name="royalty"
                  exclusive
                  className={styles.toggleButton}
                  value={get(this.state, "currentData.tail_rev_share", "0%")}
                  onChange={(e, val) => {
                    this.handleChangeOffer("tail_rev_share", val, false);
                  }}
                  data-testid="share-toggle-group"
                >
                  {get(this.state, "groupedValues.tail_rev_share", [])
                    .sort((a, b) => parseFloat(b) - parseFloat(a))
                    .map((item) => {
                      const isDisabled =
                        get(
                          this.state,
                          "toggleOptions.tailShareOptions",
                          [],
                        ).indexOf(item) === -1 || this.isFixedOffer();
                      return (
                        !!parseFloat(item) && (
                          <ToggleButton
                            key={`royalty-${item}`}
                            value={item}
                            disableRipple
                            data-testid={`share-button-${item}`}
                            disabled={isDisabled}
                          >
                            {item}
                          </ToggleButton>
                        )
                      );
                    })}
                </ToggleButtonGroup>
              </div>
            )}
            {!!get(this.state, "availablePartners.length") && (
              <div className={styles.fieldContainer}>
                <label>
                  Distributor
                  <span
                    data-testid="DistributorTooltip"
                    onClick={() => this.renderVideoContainer(OFFER_KEYS[5])}
                  >
                    <ToolTip />
                  </span>
                </label>
                <Select
                  classNamePrefix="offers"
                  styles={distributorSelectStyle}
                  onChange={(op) => {
                    this.handleChangeOffer("partner", get(op, "value"));
                  }}
                  options={this.getPartnerOptions()}
                  placeholder={`Select a Distributor`}
                  value={this.getSelectedPartner()}
                  components={{ IndicatorSeparator: null }}
                  formatOptionLabel={this.formatOptionLabel}
                  aria-label="partner-select-input"
                  data-testid="partner-select"
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.mobileFinePrint}>
          <button className={styles.linkBtn} onClick={this.toggleFinePrintText}>
            Read the Fine Print
          </button>
        </div>
      </div>
    );
  };

  renderOfferSteps = () => {
    if (get(this.state, "isOfferScreen")) {
      return this.renderCustomizeOffers();
    } else {
      return (
        <ConfirmedOffersV2
          offerMinMax={this.state.offerMinMax}
          confirmedOfferSteps={this.state.confirmedOfferSteps}
          setLoading={(loading) => {
            this.setState({ loading });
          }}
          setParentState={this.setState.bind(this)}
          {...this.props}
        />
      );
    }
  };

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu offerStage={this.state.offerStage} {...this.props} />
        <div className={styles.funnelContainer}>
          <LoginHeader
            headerTitle={"CONFIRMED OFFERS"}
            isPartnerFlow={
              get(this.state, "isOfferScreen") ||
              get(this.state, "confirmedOfferSteps.preference") ===
                MAIN_QUESTION_OPTIONS[0].OPTION
            }
            renderCustomContent={
              <div className={styles.headerOfferRange}>
                <p>
                  <span>Confirmed Offers Range</span>{" "}
                  <Tooltip
                    place="bottom"
                    light
                    id="offerRange"
                    content={
                      "This is the range of funding you can choose from when selecting an advance. Not all exact dollar figures within the range will be available."
                    }
                    delay={200}
                  />
                </p>
                <div className={styles.rangeNumbers}>
                  ${getShortNumber(get(this.state, "offerMinMax.min"))} to $
                  {getShortNumber(get(this.state, "offerMinMax.max"))}
                </div>
              </div>
            }
          />

          <div className={styles.pageContainer}>
            <div className={`${styles.mainContainer}`}>
              <div className={`${styles.scrollContainer}`}>
                {this.renderOfferSteps()}
              </div>
            </div>
          </div>
          {(this.state.loading || this.state.loadingRange) && (
            <Loader light backgroundNone />
          )}
          {this.state.shortLoading && (
            <ShortCallLoader
              loaderTitle="Hold tight"
              text={this.loaderText()}
              defaultLoader
            />
          )}
          <FinePrintModal
            isOpen={this.state.isFinePrint}
            onRequestClose={this.toggleFinePrintText}
          />
          {this.renderModal()}
        </div>
      </div>
    );
  }
}

ConfirmedOffers.contextType = ThemeContext;

export default ConfirmedOffers;

ConfirmedOffers.contextTypes = {
  slugName: PropTypes.string,
};
